import firebase from 'firebase/app';
import 'firebase/auth';

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAZvvdCePjBBe9pGOF7am1v1Nz89_8r5TM",
  authDomain: "atlas-marketing.firebaseapp.com",
  databaseURL: "https://atlas-marketing.firebaseio.com",
  projectId: "atlas-marketing",
  storageBucket: "atlas-marketing.appspot.com",
  messagingSenderId: "143542106038",
  appId: "1:143542106038:web:61a9fc32299e0729"
};

export const FIREBASE_AUTH_UI_CONFIG = {
  callbacks: {
    signInSuccessWithAuthResult: () => false
  },
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID
    }
  ]
}