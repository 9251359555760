import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/firestore';

const WEEKDAYS = {
    0: 'пн', 1: 'вт', 2: 'ср', 3: 'чт', 4: 'пт', 5: 'сб', 6: 'вс'
}
export const PARTNERS = [
    {id: 112, name: 'Атлас.Башкирия', locale: 'ru', partnerId: 1},
    {id: 114, name: 'CharterBus', locale: 'ru', partnerId: 114},
    {id: 109, name: 'Беларусь: МГБ', locale: 'by', partnerId: 108},
    {id: 106, name: 'Смарт Бас', locale: 'by', partnerId: 106},
    {id: 358, name: 'Золотое кольцо', locale: 'ru', partnerId: 358},
    {id: 133, name: 'Туртранс', locale: 'ru', partnerId: 133},
    {id: 618, name: 'ИП Семёнов', locale: 'ru', partnerId: 618},
    {id: 460, name: 'Курорт Транс', locale: 'ru', partnerId: 622, hostname: 'ktm74.ru'},
]

export const loadData = async () => {
    const lines = (await axios.get('/api/lines')).data;
    lines.forEach(l => {
        l.weekdays = l.weekdays.split(",").sort().map(w => WEEKDAYS[w])
    })

    return {lines, partners: PARTNERS}
}
export const loadFeed = (callback) => {
    let db = firebase.firestore();
    let feedCollection = db.collection('feed');
    feedCollection.orderBy("created", "desc")
        .onSnapshot(snapshot => {
            callback(snapshot.docs.map(d => d.data()))
        })
}

export const saveItem = (item, {partnerId}) => {
    let db = firebase.firestore();
    let feedCollection = db.collection('feed');
    return feedCollection.doc(item.id_line).set({
        lineId: item.id_line,
        partnerId,
        title: `${item.from.name} - ${item.to.name}`,
        created: (new Date()).valueOf()
    })
}

export const deleteItem = (item) => {
    let db = firebase.firestore();
    let feedCollection = db.collection('feed');
    return feedCollection.doc(item.lineId).delete();
}

export const filterLines = (word, lines) => {
    if (!word) {
        return lines.slice(0, 10)
    }
    let filteredWords = word.replace(new RegExp(/[^А-яA-z\s]/, 'g'), "").toLocaleLowerCase().split(' ');
    return lines.filter(v => {
        let lineName = `${v.from.name} ${v.to.name}`.toLocaleLowerCase();
        let values = filteredWords.map(w => lineName.indexOf(w) > -1);
        return values.every(Boolean)
    }).slice(0, 10)
}