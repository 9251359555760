import React from 'react';
import { Row, Col, Select, Table, Typography, Button } from 'antd'
import * as utils from './utils';


export default class Feed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lines: [],
            filteredLines: [],
            saving: false,
            dataLoading: true,
            selected: [],
            feed: [],
            partners: [],
            partner: undefined
        }
    }
    onFeedLoaded(data) {
        this.setState({feed: data})
    }
    componentDidMount() {
        utils.loadData()
            .then(({ lines, partners }) => {
                this.setState({
                    lines, partners,
                    dataLoading: false
                }, () => this.onSearch(''))
            })
        utils.loadFeed(this.onFeedLoaded.bind(this))
    }
    onSelect(value) {
        // console.log(value);
        let item = this.state.lines.filter(l => l.id_line === value)[0];

        this.setState({saving: true})
        utils.saveItem(item, {partnerId: this.state.partner})
            .then(() => {
                this.setState({saving: false});
                this.onSearch('');
            })
    }
    onSearch(value) {
        this.setState({
            filteredLines: utils.filterLines(value, this.state.lines)
        })
    }
    getPartner(feedItem) {
        let candidates = this.state.partners.filter(p => p.id === feedItem.partnerId);
        if (candidates.length > 0) {
            return candidates[0]
        }
        return {}
    }
    getLineInfo(feedItem) {
        let candidates = this.state.lines.filter(l => l.id_line === feedItem.lineId);
        if (candidates) {
            return candidates[0];
        } else {
            return null;
        }
    }
    async delete(item) {
        this.setState({saving: true});
        await utils.deleteItem(item);
        this.setState({saving: false});
    }
    render() {
        const dataSource = this.state.feed.filter(i => {
            if (!this.state.partner) {
                return true
            }
            return i.partnerId === this.state.partner;
        })
        const columns = [
            {
                title: 'Направление',
                render: i => {
                    let item = this.getLineInfo(i);
                    if (item) {
                        return <strong><a 
                            target="_blank" 
                            rel="noopener noreferrer"
                            href={item.link}
                        >{item.from.name} - {item.to.name}</a></strong>
                    } else {
                        return <strong>{i.title}</strong>
                    }
                },
                key: 'title',
            },
            {
                title: 'Партнёр',
                render: i => <span>{this.getPartner(i).name}</span>,
                key: 'partner'
            },
            {
                title: 'Текущая стомость',
                render: i => {
                    let item = this.getLineInfo(i);
                    return <span>{item ? `${item.price} руб.` : 'отсутствует на сайте'}</span>
                },
                key: 'price'
            },
            {
                title: 'Дни недели',
                render: i => {
                    let item = this.getLineInfo(i)
                    return <span>{item ? item.weekdays.join(", ") : ''}</span>
                },
                key: 'weekdays'
            },
            {
                title: 'Дата старта',
                render: i => {
                    let item = this.getLineInfo(i);
                    return <span>{item ? item.start_date : ''}</span>
                },
                key: 'start_date'
            },
            {
                title: 'Действие',
                render: i => <div><Button disabled={this.state.saving} onClick={() => this.delete(i)} type="danger">Удалить</Button></div>
            }
        ]
        return <div>
            <Row>
                <Col span={24}>
                    <Select
                        showSearch
                        allowClear
                        placeholder={this.state.dataLoading ? "Загрузка..." : "Партнёр"}
                        value={this.state.partner}
                        onSelect={v => this.setState({partner: v})}
                        style={{width: '100%', marginBottom: 18}}
                        size="large"
                    >
                        {this.state.partners.map(p => (
                            <Select.Option key={p.id} value={p.id}>{p.name}</Select.Option>
                        ))}
                    </Select>
                    <Select
                        value={this.state.selected}
                        onSelect={this.onSelect.bind(this)}
                        onSearch={this.onSearch.bind(this)}
                        optionLabelProp="title"
                        showSearch
                        style={{ width: '100%' }}
                        placeholder={
                            this.state.dataLoading ? "Загрузка..." : 
                            this.state.partner ? "Добавить направление" : "Выберите партнёра"
                        }
                        size="large"
                        disabled={!this.state.partner || this.state.dataLoading || this.state.saving}
                        showArrow={false}
                        filterOption={false}
                    >
                        {this.state.filteredLines.map(line => <Select.Option 
                            key={line.id_line} 
                            value={line.id_line}
                        >
                            <Item line={line}  />
                        </Select.Option>)}
                    </Select>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Typography.Title level={2}></Typography.Title>
                    <Table 
                        loading={this.state.dataLoading} 
                        rowKey="lineId" 
                        columns={columns} 
                        dataSource={dataSource} 
                        pagination={false}
                    />
                </Col>
            </Row>
        </div>
    }
}


const Item = ({line}) => <div style={{padding: '12px 0'}}>
    <strong>{line.from.name} — {line.to.name}</strong>, {line.price} руб.
</div>