import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css'
import { FIREBASE_AUTH_UI_CONFIG } from '../../defaults';

export default class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
    }
    componentDidMount() {
        this.ui.start(`#auth`, FIREBASE_AUTH_UI_CONFIG);
    }
    render() {
        return <div>
            <div id="auth"></div>
        </div>
    }
}