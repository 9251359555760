import React from 'react';
import 'firebaseui/dist/firebaseui.css'
import {connect} from 'react-redux'
import {checkAuth, firebaseInit} from '../../modules/auth';
import Auth from '../../components/auth';
import AppBody from '../../components/appBody';
import {Switch, Route, Redirect} from 'react-router-dom'
import Feed from '../../components/feed';

class App extends React.Component {
    constructor(props) {
        super(props);
        props.firebaseInit();
        this.props.checkAuth("auth");
    }
    render() {
        return <div>
            {(() => {
                switch (this.props.user.loggedIn) {
                    case null:
                        return <div>Авторизация...</div>
                    case false:
                        return <Auth />
                    default:
                        return <AppBody>
                            <Switch>
                                <Route exact path="/" render={() => <Redirect to="/feed" />} />
                                <Route path="/feed" component={Feed} />
                            </Switch>
                        </AppBody>
                }
            })()}
        </div>
    }
}

const mapStateToProps = state => ({user: state.auth});

const mapDispatchToProps = dispatch => ({
    checkAuth: containerId => dispatch(checkAuth(containerId)),
    firebaseInit: () => dispatch(firebaseInit())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);