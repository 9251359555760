import React from 'react';
import { Layout, Menu, Button } from 'antd';
import firebase from "firebase/app";
import "firebase/auth";

const { Header, Content, Footer } = Layout;

export default ({children}) => {
    return <Layout className="layout" style={{minHeight: '100vh'}}>
        <Header>
            <div className="logo" />
            <div style={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Menu
                    theme="dark"
                    mode="horizontal"
                    defaultSelectedKeys={['1']}
                    style={{ lineHeight: '64px' }}
                >
                    <Menu.Item key="1">Управление фидом</Menu.Item>
                </Menu>
                <div>
                    <Button type="link" style={{color: "white"}} onClick={() => firebase.auth().signOut()}>Выйти</Button>
                </div>
            </div>
        </Header>
        <Content style={{ padding: '0 50px' }}>
            {/* <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>List</Breadcrumb.Item>
                <Breadcrumb.Item>App</Breadcrumb.Item>
            </Breadcrumb> */}
            <div style={{ background: '#fff', marginTop: 32, padding: 24, minHeight: '100%' }}>
                {/* {React.Children.map(children, C => <C />)} */}
                {children}
            </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Атлас Маркетинг</Footer>
    </Layout>
}