import {FIREBASE_CONFIG} from '../defaults';
import firebase from 'firebase/app';
import 'firebase/auth';

const FIREBASE_INIT_SUCCESS = 'FIREBASE_INIT_SUCCESS';
const AUTH_START = 'AUTH_START';
const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_FORBIDDEN = 'AUTH_FORBIDDEN';

const initialState = {
    loggedIn: null,
    user: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTH_FORBIDDEN:
            return {
                ...state,
                loggedIn: false,
                user: null
            }
        case AUTH_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                user: action.payload
            }
        default:
            return state;
    }
}

export const firebaseInit = () => dispatch => {
    firebase.initializeApp(FIREBASE_CONFIG)
    dispatch({type: FIREBASE_INIT_SUCCESS})
}

export const checkAuth = (containerId) => dispatch => {
    dispatch({type: AUTH_START})
    firebase.auth().onAuthStateChanged(user => {
        if (user) {
            firebase.auth().currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    if (idTokenResult.claims.is_admin) {
                        dispatch({
                            type: AUTH_SUCCESS,
                            payload: idTokenResult
                        })
                    } else {
                        firebase.auth().signOut()
                            .then(() => window.location = "/?forbidden")
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            dispatch({type: AUTH_FORBIDDEN})
        }
    })

}